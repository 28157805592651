import React, { useState, useEffect } from 'react'; 
import { Center, Heading } from '@chakra-ui/react';
import {
  Button,
  FormControl,
  Flex,
  Input,
  Stack,
  useColorModeValue,
  HStack,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription
} from '@chakra-ui/react';
import { PinInput, PinInputField } from '@chakra-ui/react';
import { useMutation, gql } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { CHANGE_PASSWORD_EMAIL, CHANGE_PASSWORD_PIN } from '../../common/constants';

export default function VerifyPin(): JSX.Element {
  const email = localStorage.getItem(CHANGE_PASSWORD_EMAIL);
  const VERIFYPIN_MUTATION = gql`
      mutation VerifyPinMutation(
        $email: String!
        $pin: String!
      ) {
        verifyPin(email: $email, pin: $pin)
      }
    `;
  const navigate = useNavigate();
  const [formState, setFormState] = useState({
    pinInput1: '',
    pinInput2: '',
    pinInput3: '',
    pinInput4: '',
    pinInput5: '',
    pinInput6: '',
    error: false,
    error_message: '',
    disabled: true,
  });
  const checkfields = () => {
    //when copying the pin from email, it will be pasted in the first field
    if (!(formState.pinInput1.length === 6) && (formState.pinInput1 === '' || formState.pinInput2 === '' || formState.pinInput3 === '' || formState.pinInput4 === '' || formState.pinInput5 === '' || formState.pinInput6 === '')) {
      setFormState({
        ...formState,
        error: true,
        error_message: 'All pin fields are required'
      });
      return false;
    }
    verifyPin();
  }
  //when copying the pin from email, it will be pasted in the first field
  const fullPin = formState.pinInput1.length === 6 ? formState.pinInput1 :formState.pinInput1 + formState.pinInput2 + formState.pinInput3 + formState.pinInput4 + formState.pinInput5 + formState.pinInput6;

  useEffect(() => {
    console.log(formState)
  });

  const [verifyPin] = useMutation(VERIFYPIN_MUTATION, {
    variables: {
      email: email,
      pin: fullPin
    },
    onCompleted: ({ verifyPin }) => {
      if (verifyPin === 'NOTFOUND') {
        setFormState({
          ...formState,
          error: true,
          error_message: 'User account does not exist'
        }); 
      } else if (verifyPin === 'ERROR') {
        setFormState({
          ...formState,
          error: true,
          error_message: 'Error occured'
        });
      } else if (verifyPin === 'EXPIRED') {
        setFormState({
          ...formState,
          error: true,
          error_message: 'Pin expired'
        });
      } else if (verifyPin === 'WRONGPIN') {
        setFormState({
          ...formState,
          error: true,
          error_message: 'Wrong pin provided'
        });
      } else if (verifyPin === 'OK') {
        localStorage.setItem(CHANGE_PASSWORD_PIN, fullPin);
        navigate('/changePassword');
      } 
    }
});
  return (
    <Flex
      minH={'100vh'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.800')}>
      <Stack
        spacing={4}
        w={'full'}
        maxW={'sm'}
        bg={useColorModeValue('white', 'gray.700')}
        rounded={'xl'}
        boxShadow={'lg'}
        p={6}
        my={10}>
        <Center>
          <Heading lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }}>
            Verify your PIN
          </Heading>
        </Center>
        <Center
          fontSize={{ base: 'sm', sm: 'md' }}
          color={useColorModeValue('gray.800', 'gray.400')}>
          We have sent a six-digit code to your email
        </Center>
        <Center
          fontSize={{ base: 'sm', sm: 'md' }}
          fontWeight="bold"
          color={useColorModeValue('gray.800', 'gray.400')}>
          {email}
        </Center>
        <FormControl>
          <Center>
            <HStack>
              <PinInput>
                <PinInputField name="pinInput1" value={formState.pinInput1} onChange={(e) =>
                  setFormState({
                    ...formState,
                    pinInput1: e.target.value
                  })
                }/>
                <PinInputField name="pinInput2" value={formState.pinInput2} onChange={(e) =>
                  setFormState({
                    ...formState,
                    pinInput2: e.target.value
                  })
                }/>
                <PinInputField name="pinInput3" value={formState.pinInput3} onChange={(e) =>
                  setFormState({
                    ...formState,
                    pinInput3: e.target.value
                  })
                }/>
                <PinInputField name="pinInput4" value={formState.pinInput4} onChange={(e) =>
                  setFormState({
                    ...formState,
                    pinInput4: e.target.value
                  })
                }/>
                <PinInputField name="pinInput5" value={formState.pinInput5} onChange={(e) =>
                  setFormState({
                    ...formState,
                    pinInput5: e.target.value
                  })
                }/>
                <PinInputField name="pinInput6" value={formState.pinInput6} onChange={(e) =>
                  setFormState({ 
                    ...formState,
                    pinInput6: e.target.value
                  })
                }/>
              </PinInput>
            </HStack>
          </Center>
        </FormControl>
        <Stack spacing={6}>
          <Button disabled={formState.disabled}
            bg={'blue.400'}
            color={'white'}
            _hover={{
              bg: 'blue.500',
            }}
            onClick={() => checkfields()}>
            Verify PIN
          </Button>
          { formState.error ? 
            <Alert status='error'>
              <AlertIcon />
              {formState.error_message}
            </Alert> : ''
          }
        </Stack>
      </Stack>
    </Flex>
  );
}
