import * as PIXI from 'pixi.js';
import { Layer, Group, Stage } from '@pixi/layers';
import { Viewport } from 'pixi-viewport';
import { OutlineFilter } from 'pixi-filters';
import { autoDetectRenderer } from 'pixi.js';
import AActioneer from './actorActioneer';

const carAssetsManifest = {
  bundles: [
    {
      name: 'actors',
      assets: [
        {
          name: 'protagonist',
          srcs: 'https://storyi-assets-test.s3.us-west-2.amazonaws.com/car/car-faceclosed.png',
        },
        {
          name: 'stuart',
          srcs: 'https://storyi-assets-test.s3.us-west-2.amazonaws.com/test/stuart1.png',
        }
      ],
    },
    {
      name: 'scenery',
      assets: [
        {
          name: 'villagestrip',
          srcs: 'https://storyi-assets-test.s3.us-west-2.amazonaws.com/car/car-villagestrip.png',
        },
      ],
    },
    {
      name: 'props',
      assets: [
        {
          name: 'citroenleft',
          srcs: 'https://storyi-assets-test.s3.us-west-2.amazonaws.com/car/car-citroenleft.png',
        },
        {
          name: 'citroenback',
          srcs: 'https://storyi-assets-test.s3.us-west-2.amazonaws.com/car/car-citroenback.png',
        },
        {
          name: 'citroenfront',
          srcs: 'https://storyi-assets-test.s3.us-west-2.amazonaws.com/car/car-citroenfront.png',
        },
      ],
    },
  ],
};

export default class SComposer {
  constructor(mainCanvas, assetCanvas, gatedSetTarget) {
    this.mainApp = new PIXI.Application({ view: mainCanvas, width: 800, height: 600});
    this.assetApp = new PIXI.Application({ view: assetCanvas, width: 200, height: 600 }); 
    this.assetViewport = new Viewport({
      screenWidth: 200,
      screenHeight: 600,
      worldWidth: 200,
      worldHeight: 600,
      events: this.assetApp.renderer.events,
    });
    this.mainViewport = new Viewport({
      screenWidth: 800,
      screenHeight: 600,
      worldWidth: 800,
      worldHeight: 600,
      events: this.mainApp.renderer.events,
    });
    this.assetApp.stage.addChild(this.assetViewport);
    this.assetApp.stage.sortableChildren = true;
    this.assetApp.stage.interactive = true;
    this.assetApp.hitArea = app.screen;
    this.mainApp.stage = new Stage();
    this.mainApp.stage.addChild(this.mainViewport);
    this.mainViewport.interactive = true;
    this.mainViewport.hitArea = app.screen;
    this.mainViewport.sortableChildren = true;
    this.outlineFilterGreen = new OutlineFilter(2, 0x99ff99);
    this.dragTarget = null;
    this.dragPoint = new PIXI.Point();
    this.dragGroup = new Group(100, false);
    this.setTarget = gatedSetTarget;
    this.groupIndex = 0;
    this.renderer = autoDetectRenderer();
    this.preloadedTextures = {};
    this.dragTransformer = null;
  }
  getPreloadedTexture(image) {  
    return this.preloadedTextures[image];
  }
  preloadTextures(actorName, actionName) {
    const actionImageSequence = AActioneer.getActionImageSequence(actorName, actionName);
    actionImageSequence.forEach((image) => {
      if (!this.preloadedTextures[image]) {
        this.preloadedTextures[image] = PIXI.Texture.from(image);
      }
    });
  }
  getActorSprite(actorName) {
    return this.mainViewport.getChildByName(actorName, true);
  }
  disableZoom() {
    this.mainViewport.plugins.remove('wheel');
    this.mainViewport.plugins.remove('pinch');
  }
  enableZoom() {
    this.mainViewport.wheel().pinch();
  }
  disableDrag() {
    this.mainViewport.plugins.remove('drag');
  }
  enableDrag() {
    this.mainViewport.wheel().drag()
  }
  async init() {
    this.yOffset = 0;
    this.assetViewport.removeAllListeners();
    this.assetViewport.removeChildren();
  }
  async loadActors() {
    this.init();
    const actors = carAssetsManifest.bundles.find((bundle) => bundle.name === 'actors');
    for (const asset of actors.assets) {
      this.addAssetToAssetViewport(asset, true);
    }
  }
  async loadScenery() {  
    this.init();
    const scenery = carAssetsManifest.bundles.find((bundle) => bundle.name === 'scenery');

    for (const asset of scenery.assets) {
      this.addAssetToAssetViewport(asset);
    }
  }
  async loadProps() {  
    this.init();
    const scenery = carAssetsManifest.bundles.find((bundle) => bundle.name === 'props');

    for (const asset of scenery.assets) {
      this.addAssetToAssetViewport(asset);
    }
  }

  async createTextureArray(assets) {
    const textureArray = [];
    for (const asset of assets) {
      textureArray.push(await PIXI.Texture.from(asset));
    }
    console.log(textureArray);
    return textureArray;
  }

  async addAssetToAssetViewport(asset, isAnimated = false) {
    const texture = await PIXI.Assets.load(asset.srcs);
    const sprite = isAnimated? new PIXI.AnimatedSprite([texture]) : new PIXI.Sprite(texture);
    sprite.origTexture = texture;
    sprite.name = asset.name;
    this.addToAssetCanvas(sprite);
  }

  reorderViewportAssets(children) {
    this.yOffset = 0;
    const childrenCopy = [...children];
    this.assetViewport.removeAllListeners();
    this.assetViewport.removeChildren();
    for (const child of childrenCopy) {
      child.off('pointerdown');
      child.off('touchstart');
      this.addToAssetCanvas(child);
    }
  }

  addToAssetCanvas(sprite) {
    const maxWidth = 200;
    const aspectRatio = sprite.width / sprite.height;
  
    if (sprite.width > maxWidth) {
      sprite.width = maxWidth;
      sprite.height = maxWidth / aspectRatio;
    }
    sprite.x = 0;
    sprite.y = this.yOffset;

    this.yOffset += sprite.height;

    this.assetViewport.addChild(sprite);
    this.makeClickable(sprite);
  }

  makeClickable(obj) {
    obj.interactive = true;
    obj.on('pointerdown', (e) => {
      this.onClick(e, obj);
    });
    obj.on('touchstart', (e) => {
      this.onClick(e, obj);
    });
  }

  removeFromMainViewport(obj) {
    obj.off('pointerdown');
    obj.off('pointerup');
    obj.off('pointerupoutside');
    obj.off('pointermove');
    this.mainViewport.removeChild(obj);
    this.mainViewport.removeChild(obj.parentLayer);
    this.groupIndex--;
  }

  onClick(event, obj) {
    obj.off('pointerdown');
    obj.off('touchstart');
    this.assetViewport.removeChild(obj);
    this.reorderViewportAssets(this.assetViewport.children);

    obj.y = this.mainApp.screen.height / 2;
    obj.x = this.mainApp.screen.width / 2;
    const newGroup = new Group(this.groupIndex, false);
    this.groupIndex++;
    const newLayer = new Layer(newGroup);
    obj.parentGroup = newGroup;
    newLayer.group.enableSort = true;
    newLayer.addChild(obj);
    obj.parentLayer = newLayer;
    this.mainViewport.addChild(newLayer);
    this.makeDraggable(obj);
  }

  makeDraggable(obj) {
    obj.anchor.set(0.5);
    obj
      .on('pointerdown', (e) => {
        this.onDragStart(e, obj);
      })
      .on('pointerup', (e) => {
        this.onDragEnd(e, obj);
      })
      .on('pointerupoutside', (e) => {
        this.onDragEnd(e, obj);
      })
      .on('pointermove', (e) => {
        this.onDragMove(e, obj);
      });
  }

  onDragStart(event, obj) {
    obj.oldGroup = obj.parentGroup;
    obj.parentGroup = this.dragGroup;
    obj.parent.zIndex = this.dragGroup.zIndex;
    obj.toLocal(event.global, null, this.dragPoint);
    obj.dragging = true;
    this.setTarget(obj);
    this.updateDragTarget(obj);
    console.log("dragTarget", obj);
  }

  updateDragTarget(obj) {
    if (this.dragTarget) {
      this.filterOff(this.dragTarget);
    }
    this.dragTarget = obj;
    this.filterOn(obj);
  }

  onDragEnd(event, obj) {
    if (!this.dragTarget) return;
    this.dragTarget.parentGroup = this.dragTarget.oldGroup;
    this.dragTarget.parent.zIndex = this.dragTarget.oldGroup.zIndex;
    this.dragTarget.dragging = false;
  }

  onDragMove(event, object) {
    if (!this.dragTarget || !this.dragTarget.dragging) return;
    this.dragTarget.parent.toLocal(event.global, null, this.dragTarget.position);
  }

  filterOn(obj) {
    obj.filters = [this.outlineFilterGreen]; 
  }

  filterOff(obj) {
    obj.filters = null;
  }
}
