import React from 'react';
import { useRoutes } from "react-router-dom";
import Home from './components/user/home';
import Login from './components/user/login';
import Signup from './components/user/signup';
import SendPin from './components/user/sendPin';
import VerifyPin from './components/user/verifyPin';
import ChangePassword from './components/user/changePassword';
import PixiTest from './components/test/pixitest';
import SpineBoy from './components/test/spineboy';
import CarTest from './components/test/cartest';
import SceneComposer from './components/story/sceneComposer';

function App() {
  return useRoutes([
    {
      path: "/",
      element: <Home />,
    },
    { path: "login", element: <Login /> },
    { path: "signup", element: <Signup /> },
    { path: "sendPin", element: <SendPin /> },
    { path: "verifyPin", element: <VerifyPin /> },
    { path: "changePassword", element: <ChangePassword /> },
    { path: "pixiTest", element: <PixiTest /> },
    { path: "spineBoy", element: <SpineBoy /> },
    { path: "carTest", element: <CarTest /> },
    { path: "sceneComposer", element: <SceneComposer />},
  ]);
}

export default App;
