export default class AAnimator {
  constructor() {
    this.enabledActions = [];
  }

  addEnabledAction(actorName, actionName, start, end) {
    this.enabledActions.push({
      actorName,
      actionName,
      start,
      end,
    });
  }

  removeEnabledAction(actorName, actionName) {
    this.enabledActions = this.enabledActions.filter((action) => {
      return !(action.actorName === actorName && action.actionName === actionName);
    });
  }

  getEnabledActions() {
    return this.enabledActions;
  }
}
