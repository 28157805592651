import React, { useEffect, useRef, useState } from 'react';

export default function PixiTest() {
  useEffect(() => {
    const script = document.createElement('script');
  
    script.src = "../pixitest.js";
    script.type = 'module';
    script.async = true;
  
    document.body.appendChild(script);
  
    return () => {
      document.body.removeChild(script);
    }
  }, []);

  return (
    <div >
    </div>
  )
}
