const actorActions = {
  actors: [
    {
     actorName: 'protagonist',
     actions: [
        {
          actionName: 'normal-closed',
          actionImageSequence: [
            'https://storyi-assets-test.s3.us-west-2.amazonaws.com/protagonist/protagonist_0001s_0000s_0000s_0000_hair-Normal-Close.png',
            'https://storyi-assets-test.s3.us-west-2.amazonaws.com/protagonist/protagonist_0001s_0000s_0000s_0001_EYebrow-Normal-Close.png',
            'https://storyi-assets-test.s3.us-west-2.amazonaws.com/protagonist/protagonist_0001s_0000s_0000s_0002_EYelids-Normal-Close.png',
            'https://storyi-assets-test.s3.us-west-2.amazonaws.com/protagonist/protagonist_0001s_0000s_0000s_0003_eyes--Normal-Close.png',
            'https://storyi-assets-test.s3.us-west-2.amazonaws.com/protagonist/protagonist_0001s_0000s_0000s_0004_Mustache--Normal-Close.png',
            'https://storyi-assets-test.s3.us-west-2.amazonaws.com/protagonist/protagonist_0001s_0000s_0000s_0005_Beard-Normal-Close.png',
            'https://storyi-assets-test.s3.us-west-2.amazonaws.com/protagonist/protagonist_0001s_0000s_0000s_0006_Normal-Close.png',
          ],
        },
      ],
    },
    {
      actorName: 'stuart',
      actions: [
        {
          actionName: 'do weird things',
          actionImageSequence: [
            'https://storyi-assets-test.s3.us-west-2.amazonaws.com/test/stuart1.png',
            'https://storyi-assets-test.s3.us-west-2.amazonaws.com/test/stuart2.png',
            'https://storyi-assets-test.s3.us-west-2.amazonaws.com/test/stuart3.png',
          ],
        },
        {
          actionName: 'point',
          actionImageSequence: [
            'https://storyi-assets-test.s3.us-west-2.amazonaws.com/test/stuart3.png',
            'https://storyi-assets-test.s3.us-west-2.amazonaws.com/test/stuart1.png',
            'https://storyi-assets-test.s3.us-west-2.amazonaws.com/test/stuart3.png',
          ]
        }
      ],
    },
  ],
}

export default class AActioneer {
  static getAllActorActions(actorName) {
    return actorActions.actors.find((actor) => actor.actorName === actorName);
  }
  static getActionImageSequence(actorName, actorAction) {
    const actor = AActioneer.getAllActorActions(actorName);
    if (!actor) return [];
    const action = actor.actions.find((action) => action.actionName === actorAction);
    if (!action) return [];
    return action.actionImageSequence;
  }
}
