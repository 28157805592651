import React from 'react';
import {
  BrowserRouter,
} from "react-router-dom";
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from '@apollo/client';
import { ChakraProvider } from '@chakra-ui/react';
import { setContext } from '@apollo/client/link/context';
import { AUTH_TOKEN } from './common/constants';
import { GoogleOAuthProvider } from '@react-oauth/google';

const rootNode = document.getElementById('app') as HTMLElement;

const httpLink = createHttpLink({
  uri: process.env.API_URL,
  fetchOptions: {
    mode: 'cors'
  }
});
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(AUTH_TOKEN);
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  };
});
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  credentials: 'include',
});

if(rootNode) {
  const root = ReactDOM.createRoot(
    rootNode
  );
  root.render(
    <ApolloProvider client={client}>
      <GoogleOAuthProvider clientId="989949673699-bpeq1r6tq7pnt3l4gnjgqo4eb4ctavnl.apps.googleusercontent.com">
        <ChakraProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ChakraProvider>
      </GoogleOAuthProvider>
    </ApolloProvider>
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
