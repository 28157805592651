import React, { useState } from 'react';
import { AUTH_TOKEN } from '../../common/constants';
import { useNavigate } from 'react-router-dom';

import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Stack,
  Link,
  Button,
  Heading,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';

export default function Home() {
  const authToken = localStorage.getItem(AUTH_TOKEN);
  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem(AUTH_TOKEN);
    navigate('/')
  };
  if (authToken) {
    return (
      <Box>
        <VStack>
          <Text>Logged In</Text>
          <Text>Token: {authToken}</Text>
          <Link onClick={logout}>Logout</Link>
        </VStack>
      </Box>
    );
  } else {
    return (
      <Box>
        <VStack>
          <Link href="/login">Log in Test</Link>
          <Link href="/pixiTest">Pixi Test</Link>
          <Link href="/spineBoy">SpineBoy Bones Test</Link>
          <Link href="/carTest">Car Driving Test</Link>
          <Link href="/sceneComposer">Scene Composer Test</Link>
        </VStack>
      </Box>
    );
  }
}