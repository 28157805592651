import React, { useState, useEffect } from 'react';
import { useMutation, gql } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { Md5 } from 'ts-md5';
import { useGoogleLogin } from '@react-oauth/google';
import { FcGoogle } from 'react-icons/fc';

import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    Checkbox,
    Stack,
    Link,
    Button,
    Heading,
    Text,
    useColorModeValue,
    HStack,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
  } from '@chakra-ui/react';
  

  export default function Signup() {
    const [googleToken, setGoogleToken] = useState({
      token: '',
    });
    const SIGNUP_WITH_GOOGLE_MUTATION = gql`
      mutation SignupWithGoogleMutation(
        $token: String!
      ) {
        signupWithGoogle(token: $token)
      }
    `;
    useEffect(() => {
      if (googleToken.token !== '') {
        signupWithGoogle();
      }
    },[googleToken]);
    const [signupWithGoogle] = useMutation(SIGNUP_WITH_GOOGLE_MUTATION, {
      variables: {
        token: googleToken.token,
      },
      onCompleted: ({ signupWithGoogle }) => {
        if (signupWithGoogle === 'ERROR') {
          setFormState({
            ...formState,
            error: true,
            error_message: 'Error occurred'
          });
        } else if (signupWithGoogle === 'EXISTS') {
          setFormState({
            ...formState,
            error: true,
            error_message: 'Email already exists'
          });
        } else if (signupWithGoogle === 'OK') {
          navigate('/');
        }
      }
    });
    const googleSignup = useGoogleLogin({
      onSuccess: tokenResponse => {
        console.log(tokenResponse);
        setGoogleToken({
          ...googleToken,
          token: tokenResponse.access_token,
        });
      },
      onError: error => {
        console.log(error);
        setFormState({
          ...formState,
          error: true,
          error_message: 'Google sign up failed'
        });
      },
    });
    const SIGNUP_MUTATION = gql`
      mutation SignupMutation(
        $username: String!
        $firstname: String!
        $lastname: String!
        $email: String!
        $password: String!
      ) {
        signup(username: $username, firstname: $firstname, lastname: $lastname, email: $email, password: $password)
      }
    `;
    const navigate = useNavigate();
    const [formState, setFormState] = useState({
      username: '',
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      confirmpassword: '',
      error: false,
      error_message: '',
      show_password: false,
    });
    const checkfields = () => {
      if (formState.username === '') {
        setFormState({
          ...formState,
          error: true,
          error_message: 'Username is required'
        });
        return false;
      }
      if (formState.firstname === '') {
        setFormState({
          ...formState,
          error: true,
          error_message: 'First Name is required'
        });
        return false;
      }
      if (formState.lastname === '') {
        setFormState({
          ...formState,
          error: true,
          error_message: 'Last Name is required'
        });
        return false;
      }
      if (formState.email === '') {
        setFormState({
          ...formState,
          error: true,
          error_message: 'Email is required'
        });
        return false;
      }
      if (formState.password === '') {
        setFormState({
          ...formState,
          error: true,
          error_message: 'Password is required'
        });
        return false;
      }
      if (formState.confirmpassword === '') {
        setFormState({
          ...formState,
          error: true,
          error_message: 'Confirm Password is required'
        });
        return false;
      }
      if (formState.password !== formState.confirmpassword) {
        setFormState({
          ...formState,
          error: true,
          error_message: 'Passwords do not match'
        });
        return false;
      }
      signup();
    };
    const [signup] = useMutation(SIGNUP_MUTATION, {
      variables: {
        username: formState.username,
        firstname: formState.firstname,
        lastname: formState.lastname,
        email: formState.email,
        password: Md5.hashStr(formState.password + process.env.SALT)
      },
      onCompleted: ({ signup }) => {
        if (signup === 'ERROR') {
          setFormState({
            ...formState,
            error: true,
            error_message: 'Error occurred'
          });
        } else if (signup === 'EXISTS') {
          setFormState({
            ...formState,
            error: true,
            error_message: 'Email already exists'
          });
        } else if (signup === 'OK') {
          navigate('/');
        }
      }
    });
    return (
      <Flex
        minH={'100vh'}
        align={'center'}
        justify={'center'}
        bg={useColorModeValue('gray.50', 'gray.800')}>
        <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
          <Stack align={'center'}>
            <Heading fontSize={'4xl'}>Create your account</Heading>
            <Text fontSize={'lg'} color={'gray.600'}>
              to enjoy stories
            </Text>
          </Stack>
          <Box
            rounded={'lg'}
            bg={useColorModeValue('white', 'gray.700')}
            boxShadow={'lg'}
            p={8}>
            <Stack spacing={4}>
              <FormControl id="username" isRequired>
                <FormLabel>Username</FormLabel>
                <Input type="text" value={formState.username} onChange={(e) =>
                  setFormState({
                    ...formState,
                    username: e.target.value
                  })
                }/>
              </FormControl>
              <HStack>
                <Box>
                  <FormControl id="firstname" isRequired>
                    <FormLabel>First Name</FormLabel>
                    <Input type="text" value={formState.firstname} onChange={(e) =>
                      setFormState({
                        ...formState,
                        firstname: e.target.value
                      })
                    }/>
                  </FormControl>
                </Box>
                <Box>
                  <FormControl id="lastName" isRequired>
                    <FormLabel>Last Name</FormLabel>
                    <Input type="text" value={formState.lastname} onChange={(e) =>
                      setFormState({
                        ...formState,
                        lastname: e.target.value
                      })
                    }/>
                  </FormControl>
                </Box>
              </HStack>
              <FormControl id="email" isRequired>
                <FormLabel>Email address</FormLabel>
                <Input type="email" value={formState.email} onChange={(e) =>
                  setFormState({
                    ...formState,
                    email: e.target.value
                  })
                }/>
              </FormControl>
              <FormControl id="password" isRequired>
                <FormLabel>Password</FormLabel>
                <Input type={formState.show_password  ? "text" : "password"} value={formState.password} onChange={(e) =>
                  setFormState({
                    ...formState,
                    password: e.target.value
                  })
                }/>
              </FormControl>
              <FormControl id="confirmpassword" isRequired>
                <FormLabel>Confirm Password</FormLabel>
                <Input type={formState.show_password  ? "text" : "password"} value={formState.confirmpassword} onChange={(e) =>
                  setFormState({
                    ...formState,
                    confirmpassword: e.target.value
                  })
                }/>
              </FormControl>
              <Stack spacing={10}>
                <Stack
                  direction={{ base: 'column', sm: 'row' }}
                  align={'start'}
                  justify={'space-between'}>
                  <Checkbox isChecked={formState.show_password} onChange={(e) =>
                    setFormState({
                      ...formState,
                      show_password: e.target.checked
                    })}>Show password</Checkbox>
                </Stack>
                <Stack>
                  <Button
                    bg={'blue.400'}
                    color={'white'}
                    _hover={{
                      bg: 'blue.500',
                    }}
                    onClick={() => checkfields()}
                    >
                    Sign up
                  </Button>
                  <Text align="center">or</Text>
                  <Button
                    leftIcon={<FcGoogle />}
                    bg={'blue.400'}
                    color={'white'}
                    _hover={{
                      bg: 'blue.500',
                    }}
                    onClick={() => googleSignup()}
                    >
                    Sign up with Google
                  </Button>
                </Stack>
                { formState.error ? 
                  <Alert status='error'>
                    <AlertIcon />
                    {formState.error_message}
                  </Alert> : ''
                }
                <Text>Already a user? <Link color={'blue.400'} href='/login'>Click here to log in</Link></Text>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Flex>
    );
  }
