import React, { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { CHANGE_PASSWORD_EMAIL } from '../../common/constants';

import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    Checkbox,
    Stack,
    Link,
    Button,
    Heading,
    Text,
    useColorModeValue,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
  } from '@chakra-ui/react';
  

  export default function SendPin() {
    const SENDPIN_MUTATION = gql`
      mutation SendPinMutation(
        $email: String!
      ) {
        sendPin(email: $email)
      }
    `;
  const navigate = useNavigate();
  const [formState, setFormState] = useState({
    login: true,
    email: '',
    error: false,
    error_message: '',
  });
  const checkfields = () => {
    if (formState.email === '') {
      setFormState({
        ...formState,
        error: true,
        error_message: 'Email is required'
      });
      return false;
    }
    sendPin();
  }
  const [sendPin] = useMutation(SENDPIN_MUTATION, {
    variables: {
      email: formState.email
    },
    onCompleted: ({ sendPin }) => {
      if (sendPin === 'NOTFOUND') {
        setFormState({
          ...formState,
          error: true,
          error_message: 'User account does not exist'
        }); 
      } else if (sendPin === 'ERROR') {
        setFormState({
          ...formState,
          error: true,
          error_message: 'Error occured'
        });
      } else if (sendPin === 'OK') {
        localStorage.setItem(CHANGE_PASSWORD_EMAIL, formState.email);
        navigate('/verifyPin');
      } 
    }
});
    return (
      <Flex
        minH={'100vh'}
        align={'center'}
        justify={'center'}
        bg={useColorModeValue('gray.50', 'gray.800')}>
        <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
          <Stack align={'center'}>
            <Heading fontSize={'4xl'}>Reset Your Password</Heading>
            <Text fontSize={'lg'} color={'gray.600'}>
              Enter your email address to send a PIN
            </Text>
          </Stack>
          <Box
            rounded={'lg'}
            bg={useColorModeValue('white', 'gray.700')}
            boxShadow={'lg'}
            p={8}>
            <Stack spacing={4}>
              <FormControl id="email" isRequired>
                <FormLabel>Email address</FormLabel>
                <Input type="email"  value={formState.email} onChange={(e) =>
                  setFormState({
                    ...formState,
                    email: e.target.value
                  })
                }/>
              </FormControl>
              <Stack spacing={10}>
                <Button
                  bg={'blue.400'}
                  color={'white'}
                  _hover={{
                    bg: 'blue.500',
                  }}
                  onClick={() => checkfields()}
                  >
                  Send PIN
                </Button>
                { formState.error ? 
                  <Alert status='error'>
                    <AlertIcon />
                    {formState.error_message}
                  </Alert> : ''
                }
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Flex>
    );
  }
