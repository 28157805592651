import React, { useState, useEffect } from "react";
import { 
  VStack,
  IconButton,
  HStack,
  Text,
  SimpleGrid,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
  Box
} from "@chakra-ui/react";
import { MdFastForward, MdFastRewind, MdPlayArrow, MdPause, MdStop, MdGraphicEq } from "react-icons/md";
import AActioneer from "../../../lib/actorActioneer";
interface ActorAnimatorProps {
  composer: any;
  duration: number;
  animator: any;
}

export default function ActorAnimator({composer, duration, animator}: ActorAnimatorProps) {
  const [animation, setAnimation] = useState({
    isPaused: true,
    animationStartTime: 0,
    lastStartTime: 0,
    animationProgress: 0,
  });

  const updateAnimation = (progress: number) => {
    if(!animator) return;
    animator.getEnabledActions().forEach((action: any) => {
      const actorSprite = composer.getActorSprite(action.actorName);
      console.log('progress', progress, 'action', action);
      if (progress >= action.start && progress <= action.end) {
        const actionImageSequence = AActioneer.getActionImageSequence(action.actorName, action.actionName);
        const actionImageSequenceLength = actionImageSequence.length;
        const actionImageSequenceDuration = action.end - action.start;
        const actionImageSequenceFrameDuration = actionImageSequenceDuration / actionImageSequenceLength;
        const frameIndex = Math.floor((progress - action.start) / actionImageSequenceFrameDuration);
        const frameImage = actionImageSequence[frameIndex];
        actorSprite.texture = composer.getPreloadedTexture(frameImage);
        console.log('frameImage', frameImage, 'progress', progress, 'actionImageSequenceFrameDuration', actionImageSequenceFrameDuration, 'frameIndex', frameIndex, 'actorSprite', actorSprite);
      }
    });
  }

  const play = () => {
    setAnimation({isPaused: false, animationStartTime: 0, lastStartTime: animation.animationStartTime, animationProgress: animation.animationProgress});
  }

  const pause = () => {
    setAnimation({isPaused: true, animationStartTime: 0, lastStartTime: animation.animationStartTime, animationProgress: animation.animationProgress});
  }

  const stop = () => {
    setAnimation({isPaused: true, animationStartTime: 0, lastStartTime: animation.animationStartTime, animationProgress: 0});
  }

  const forward = () => {
    setAnimation({isPaused: true, animationStartTime: 0, lastStartTime: animation.animationStartTime, animationProgress: animation.animationProgress += 1000});
  }

  const rewind = () => {
    let progress = animation.animationProgress - 1000; // Move backward 1 second
    if (progress < 0) {
      progress = 0;
    }
    setAnimation({isPaused: true, animationStartTime: 0, lastStartTime: animation.animationStartTime, animationProgress: progress});
  }

  const frame = React.useRef(0);
  // keep track of when animation is started
  const firstFrameTime = React.useRef(performance.now());

  const animate = (time: number) => {
    if (animation.animationStartTime === 0) {
      setAnimation((prevState) => ({isPaused: animation.isPaused, animationStartTime: time - animation.animationProgress, lastStartTime: animation.animationStartTime, animationProgress: animation.animationProgress}));
      return;
    }
    const delta = time - animation.animationStartTime;
    if (animation.animationProgress > duration) {
      setAnimation((prevState) => ({isPaused: true, animationStartTime: time - animation.animationProgress, lastStartTime: animation.animationStartTime, animationProgress: delta}));
      return;
    }
    setAnimation((prevState) => ({isPaused: animation.isPaused, animationStartTime: time - animation.animationProgress, lastStartTime: animation.animationStartTime, animationProgress: delta}));
    frame.current = requestAnimationFrame(animate);
}

useEffect(() => {
  updateAnimation(animation.animationProgress);
  if (animation.isPaused) {
    cancelAnimationFrame(frame.current);
    return;
  }
  firstFrameTime.current = performance.now();
  frame.current = requestAnimationFrame(animate);
  return() => cancelAnimationFrame(frame.current);
}, [animation]);

  return (
    <VStack>
      <HStack padding={2}>
        <IconButton bg='turquoise' aria-label="scaleDown" icon={<MdFastRewind />} onClick={rewind} />
        <IconButton bg='turquoise' aria-label="scaleDown" icon={<MdStop />} onClick={stop} />
        <IconButton bg='turquoise' aria-label="scaleDown" icon={<MdPause />} onClick={pause} />
        <IconButton bg='turquoise' aria-label="scaleDown" icon={<MdPlayArrow />} onClick={play} />
        <IconButton bg='turquoise' aria-label="scaleDown" icon={<MdFastForward />} onClick={forward} />
      </HStack>
      <SimpleGrid key='progress' minWidth={400}>
      <Slider aria-label='progress slider' value={animation.animationProgress} defaultValue={animation.animationProgress} onChange={(pos) => setAnimation({isPaused: animation.isPaused, animationStartTime: 0, lastStartTime: animation.animationStartTime, animationProgress: pos})} min={0} max={duration} step={10}>
        <SliderTrack bg='green.100'>
          <SliderFilledTrack bg='turquoise' />
        </SliderTrack>
        <SliderThumb boxSize={6}>
          <Box color='turquoise' as={MdGraphicEq} />
        </SliderThumb>
      </Slider>
      </SimpleGrid>
      <Text>Animation Start Time: {Math.round(animation.animationStartTime)}</Text>
      <Text>Animation Paused:
        {animation.isPaused ? 'Yes' : 'No'}
      </Text>
      <Text>Animation Progress: {Math.round(animation.animationProgress)}</Text>
    </VStack>
  );
}
