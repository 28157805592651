import React, { useState } from "react";
import { 
  VStack,
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  Checkbox,
  SimpleGrid,
  HStack,
  Text,
} from "@chakra-ui/react";
import AActioneer from "../../../lib/actorActioneer";
interface ActorActioneerProps {
  composer: any;
  dragTarget: any;
  duration: number;
  animator:any;
}

export default function ActorActioneer({composer, dragTarget, duration, animator}: ActorActioneerProps) {
  const [action, setAction] = useState<IAction>({
    actors: [],
  });

  const enableActorAction = (actorName: string, actionName: string, enabled: boolean) => {
    const actorsCopy = [...action.actors];
    const actor = actorsCopy.find((actorName) => actorName.actorName === dragTarget.name);
    if (!actor) return false;
    const findAction = actor.actions.find((action) => action.actionName === actionName);
    if (!findAction) return false;
    findAction.enabled = enabled;
    setAction({actors: actorsCopy});
    if (composer === null) return false;
    composer.preloadTextures(actor.actorName, findAction.actionName);
    if (enabled) {
      animator.addEnabledAction(actor.actorName, findAction.actionName, findAction.start, findAction.end);
    } else {
      animator.removeEnabledAction(actor.actorName, findAction.actionName);
    }
  };

  const addActorAction = (actorName: string, actionName: string, start: number, end: number) => {
    const actorsCopy = [...action.actors];
    const actor = actorsCopy.find((actorName) => actorName.actorName === dragTarget.name);
    if (!actor) {
      const newActor = {actorName: dragTarget.name, actions: [{actionName: actionName, start: start, end: end, enabled: false}]};
      actorsCopy.push(newActor);
    } else {
      const findAction = actor.actions.find((action) => action.actionName === actionName);
      if (!findAction)  {
        actor.actions.push({actionName: actionName, start: start, end: end, enabled: false});
      } else {
        findAction.start = start;
        findAction.end = end;
      }
    }
    setAction({actors: actorsCopy});
  }

  const getActorAction = (actorName: string, actionName: string) => {
    const defaultAction = {actionName: '', start: 0, end: 0, enabled: false};
    const findActor = action.actors.find((actor) => actor.actorName === actorName);
    if (!findActor) return defaultAction;
    const findAction = findActor.actions.find((action) => action.actionName === actionName);
    if (!findAction) return defaultAction;
    return findAction;
  }


  const actorActions = () => {
    if (!dragTarget) return null;
    const actions = AActioneer.getAllActorActions(dragTarget.name);
    let actionArray = [];
    if (actions) {
      for (const action of actions.actions) {
        actionArray.push(
          <SimpleGrid key={action.actionName} minWidth={400}>
            <Text>{action.actionName}</Text>
            <HStack>
            <RangeSlider data-testid={'rangeSlider'} name={action.actionName} aria-label={['start', 'finish']} min={0} max={duration} step={10} 
              defaultValue={[getActorAction(dragTarget.name, action.actionName).start,getActorAction(dragTarget.name, action.actionName).end]} colorScheme='green'
              onChangeEnd={(pos) => addActorAction(dragTarget.name, action.actionName, pos[0], pos[1])}>
              <RangeSliderTrack>
                <RangeSliderFilledTrack />
              </RangeSliderTrack>
              <RangeSliderThumb index={0} />
              <RangeSliderThumb index={1} />
            </RangeSlider>
            <Checkbox name={action.actionName} isChecked={getActorAction(dragTarget.name, action.actionName).enabled} 
              onChange={(e) => {enableActorAction(dragTarget.name, action.actionName, e.target.checked)}}></Checkbox>
            </HStack>
          </SimpleGrid>
        );
      }
    }
    return actionArray;
  };

  return (
    <VStack padding={2}>
      {actorActions()}
    </VStack>
  );
}
