import React, { useState, useEffect, useRef, createRef, useCallback } from "react";
import SComposer from "../../lib/sceneComposer";
import ActorActioneer from "./children/actorActioneer";
import ActorAnimator from "./children/actorAnimator";
import AAnimator from "../../lib/actorAnimator";

import { 
  HStack,
  Button,
  IconButton,
  Text,
  SimpleGrid,
  VStack,
  Flex,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Center,
} from "@chakra-ui/react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";

export default function SceneComposer() {
  const mainCanvasRef = useRef(null);
  const assetCanvasRef = useRef(null);

  const [scene, setScene] = useState<IScene>({
    dragTarget: null,
  });

  const [composer, setComposer] = useState<IComposer>({
    composer: null,
  });

  const [animator, setAnimator] = useState<IAnimator>({
    animator: null,
  });

  const [state, setState] = useState({
    view: 'main',
  });

  const [camera, setCamera] = useState({
    zoom: false,
    drag: false,
  });

  const zIndexUp = () => {
    scene.dragTarget.oldGroup.zIndex += 1;
    scene.dragTarget.parent.zIndex += 1;
    scene.dragTarget.parent.parent.sortChildren();
    setScene({dragTarget: scene.dragTarget});
  };

  const zIndexDown = () => {
    scene.dragTarget.oldGroup.zIndex -= 1;
    scene.dragTarget.parent.zIndex -= 1;
    scene.dragTarget.parent.parent.sortChildren();
    setScene({dragTarget: scene.dragTarget});
  };

  const scaleUp = () => {
    scene.dragTarget.scale.x *= 1.1;
    scene.dragTarget.scale.y *= 1.1;
    setScene({dragTarget: scene.dragTarget});
  };

  const scaleDown = () => {
    scene.dragTarget.scale.x *= 0.9;
    scene.dragTarget.scale.y *= 0.9;
    setScene({dragTarget: scene.dragTarget});
  };

  const removeFromMain = () => {
    if (composer.composer === null) return;
    composer.composer.removeFromMainViewport(scene.dragTarget);
    setScene({dragTarget: null});
  };
  const enableZoom = () => {
    if (composer.composer === null) return;
    composer.composer.enableZoom();
    setCamera({zoom: true, drag: camera.drag});
  };
  const disableZoom = () => {
    if (composer.composer === null) return;
    composer.composer.disableZoom();
    setCamera({zoom: false, drag: camera.drag});
  };
  const enableDrag = () => {
    if (composer.composer === null) return;
    composer.composer.enableDrag();
    setCamera({zoom: camera.zoom, drag: true});
  };
  const disableDrag = () => {
    if (composer.composer === null) return;
    composer.composer.disableDrag();
    setCamera({zoom: camera.zoom, drag: false});
  };
  const loadActors = () => {
    if (composer.composer === null) return;
    composer.composer.loadActors();
  };
  const loadScenery = () => {
    if (composer.composer === null) return;
    composer.composer.loadScenery();
  };
  const loadProps = () => {
    if (composer.composer === null) return;
    composer.composer.loadProps();
  };

  const gatedSetTarget = useCallback((value: any) => {
    setScene({dragTarget: value});
  }, []);

  useEffect(() => {
    const mainCanvas = mainCanvasRef.current;
    const assetCanvas = assetCanvasRef.current;
    setComposer({composer: new SComposer(mainCanvas, assetCanvas, gatedSetTarget)});
    setAnimator({animator: new AAnimator()});
  }, []);

  const showZIndex = () => {
    if (scene !== null && scene.dragTarget !== null)  {
      return scene.dragTarget.oldGroup.zIndex;
    }
    return null;
  };
 
  const duration = 10000; // 10 seconds

  return (
    <Flex direction={'column'} alignItems={'center'} padding={'5'}>
        <Center bg='orange' w='80%' p={4} color='white'>
          This is where you make a scene
        </Center>
        <Center bg='lightblue' w='100%' padding={'5'}>
          <VStack>
            <HStack>
              <Button onClick={loadActors} bg='turquoise'>Add Actors</Button>
              <Button onClick={loadScenery} bg='turquoise'>Add Scenery</Button>
              <Button onClick={loadProps} bg='turquoise'>Add Props</Button>
            </HStack>
            <HStack>
              <canvas ref={mainCanvasRef} width="800" height="600"  />
              <canvas ref={assetCanvasRef} width="200" height="600" />
            </HStack>
            <Tabs variant='soft-rounded' colorScheme='green' defaultIndex={state.view==='acting'?1:0}>
              <TabList>
                <Tab>Target</Tab>
                <Tab>Lights</Tab>
                <Tab>Camera</Tab>
                <Tab>Action</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <SimpleGrid columns={2}>
                    <HStack padding={2}>
                      <Text>zIndex: {showZIndex()}</Text>
                    </HStack>
                    <HStack padding={2}>
                      <IconButton bg='turquoise' aria-label="zIndexUp" icon={<MdKeyboardArrowUp />} onClick={zIndexUp} isDisabled={scene.dragTarget === null} />
                      <IconButton bg='turquoise' aria-label="zIndexDown" icon={<MdKeyboardArrowDown />} onClick={zIndexDown} isDisabled={scene.dragTarget === null} />
                    </HStack>
                    <HStack padding={2}>
                      <Text>
                        scaleX: {scene.dragTarget ? Math.round(scene.dragTarget.scale.x*100)/100 : ''}<br />
                        scaleY: {scene.dragTarget ? Math.round(scene.dragTarget.scale.y*100)/100 : ''}
                      </Text>
                    </HStack>
                    <HStack padding={2}>
                      <IconButton bg='turquoise' aria-label="scaleUp" icon={<MdKeyboardArrowUp />} onClick={scaleUp} isDisabled={scene.dragTarget === null} />
                      <IconButton bg='turquoise' aria-label="scaleDown" icon={<MdKeyboardArrowDown />} onClick={scaleDown} isDisabled={scene.dragTarget === null} />
                    </HStack>
                    <HStack padding={2}>
                      <Button bg='turquoise' onClick={removeFromMain} isDisabled={scene.dragTarget === null}>Remove</Button>
                    </HStack>
                  </SimpleGrid>
                </TabPanel>
                <TabPanel>
                  <Text>Filters go here</Text>
                </TabPanel>
                <TabPanel>
                  <HStack padding={2}>
                    <Button bg='turquoise' onClick={camera.zoom?disableZoom:enableZoom}>{camera.zoom?'Disable Zoom':'Enable Zoom'}</Button>
                    <Button bg='turquoise' onClick={camera.drag?disableDrag:enableDrag}>{camera.drag?'Disable Drag':'Enable Drag'}</Button>
                  </HStack>
                </TabPanel>
                <TabPanel>
                  <ActorActioneer composer={composer.composer} dragTarget={scene.dragTarget} duration={duration} animator={animator.animator} />
                  <ActorAnimator composer={composer.composer} duration={duration} animator={animator.animator} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </VStack>
        </Center>
    </Flex>
  );
}
