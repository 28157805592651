import React, { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { CHANGE_PASSWORD_EMAIL, CHANGE_PASSWORD_PIN } from '../../common/constants';
import { Md5 } from 'ts-md5';

import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    Checkbox,
    Stack,
    Link,
    Button,
    Heading,
    Text,
    useColorModeValue,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
  } from '@chakra-ui/react';
  

  export default function ChangePassword() {
    const CHANGEPASSWORD_MUTATION = gql`
      mutation ChangePasswordMutation(
        $email: String!
        $password: String!
        $pin: String!
      ) {
        changePassword(email: $email, password: $password, pin: $pin)
      }
    `;
  const navigate = useNavigate();
  const [formState, setFormState] = useState({
    password: '',
    confirmpassword: '',
    error: false,
    error_message: '',
    show_password: false,
  });
  const checkfields = () => {
    if (formState.password === '') {
      setFormState({
        ...formState,
        error: true,
        error_message: 'Password is required'
      });
      return false;
    }
    if (formState.confirmpassword === '') {
      setFormState({
        ...formState,
        error: true,
        error_message: 'Confirm Password is required'
      });
      return false;
    }
    if (formState.password !== formState.confirmpassword) {
      setFormState({
        ...formState,
        error: true,
        error_message: 'Passwords do not match'
      });
      return false;
    }
    changePassword();
  }
  const [changePassword] = useMutation(CHANGEPASSWORD_MUTATION, {
    variables: {
      email: localStorage.getItem(CHANGE_PASSWORD_EMAIL),
      password: Md5.hashStr(formState.password + process.env.SALT),
      pin: localStorage.getItem(CHANGE_PASSWORD_PIN)
    },
    onCompleted: ({ changePassword }) => {
      if (changePassword === 'NOTFOUND') {
        setFormState({
          ...formState,
          error: true,
          error_message: 'User account does not exist'
        }); 
      } else if (changePassword === 'ERROR') {
        setFormState({
          ...formState,
          error: true,
          error_message: 'Error occured'
        });
      } else if (changePassword === 'EXPIRED') {
        setFormState({
          ...formState,
          error: true,
          error_message: 'Pin has expired'
        });
      } else if (changePassword === 'OK') {
        localStorage.removeItem(CHANGE_PASSWORD_EMAIL);
        localStorage.removeItem(CHANGE_PASSWORD_PIN);
        navigate('/login');
      } 
    }
});
    return (
      <Flex
        minH={'100vh'}
        align={'center'}
        justify={'center'}
        bg={useColorModeValue('gray.50', 'gray.800')}>
        <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
          <Stack align={'center'}>
            <Heading fontSize={'4xl'}>Reset Your Password</Heading>
            <Text fontSize={'lg'} color={'gray.600'}>
              Enter your new password to change it
            </Text>
          </Stack>
          <Box
            rounded={'lg'}
            bg={useColorModeValue('white', 'gray.700')}
            boxShadow={'lg'}
            p={8}>
            <Stack spacing={4}>
              <FormControl id="password" isRequired>
                <FormLabel>New Password</FormLabel>
                <Input type={formState.show_password  ? "text" : "password"} value={formState.password} onChange={(e) =>
                  setFormState({
                    ...formState,
                    password: e.target.value
                  })
                }/>
              </FormControl>
              <FormControl id="confirmpassword" isRequired>
                <FormLabel>Confirm Password</FormLabel>
                <Input type={formState.show_password  ? "text" : "password"} value={formState.confirmpassword} onChange={(e) =>
                  setFormState({
                    ...formState,
                    confirmpassword: e.target.value
                  })
                }/>
              </FormControl>
              <Stack spacing={10}>
                <Stack
                  direction={{ base: 'column', sm: 'row' }}
                  align={'start'}
                  justify={'space-between'}>
                  <Checkbox isChecked={formState.show_password} onChange={(e) =>
                    setFormState({
                      ...formState,
                      show_password: e.target.checked
                    })}>Show password</Checkbox>
                </Stack>
                <Button
                  bg={'blue.400'}
                  color={'white'}
                  _hover={{
                    bg: 'blue.500',
                  }}
                  onClick={() => checkfields()}
                  >
                  Change password
                </Button>
                { formState.error ? 
                  <Alert status='error'>
                    <AlertIcon />
                    {formState.error_message}
                  </Alert> : ''
                }
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Flex>
    );
  }
