import React, { useState, useEffect } from 'react';
import { useMutation, gql } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { AUTH_TOKEN, REMEMBER_ME_EMAIL } from '../../common/constants';
import { Md5 } from 'ts-md5';
import { useGoogleLogin } from '@react-oauth/google';
import { FcGoogle } from 'react-icons/fc';

import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    Checkbox,
    Stack,
    Link,
    Button,
    Heading,
    Text,
    useColorModeValue,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
  } from '@chakra-ui/react';

  export default function Login() {
    const [googleToken, setGoogleToken] = useState({
      token: '',
    });
    const LOGIN_WITH_GOOGLE_MUTATION = gql`
      mutation LoginWithGoogleMutation(
        $token: String!
      ) {
        loginWithGoogle(token: $token)
      }
    `;
    useEffect(() => {
      if (googleToken.token !== '') {
        loginWithGoogle();
      }
    },[googleToken]);

    const [loginWithGoogle] = useMutation(LOGIN_WITH_GOOGLE_MUTATION, {
      variables: {
        token: googleToken.token,
      },
      onCompleted: ({ loginWithGoogle }) => {
        if (loginWithGoogle === 'NOTFOUND') {
          setFormState({
            ...formState,
            error: true,
            error_message: 'User account does not exist'
          });
        } else if (loginWithGoogle === 'ERROR') {
          setFormState({
            ...formState,
            error: true,
            error_message: 'Error occured'
          });
        } else {
          localStorage.setItem(AUTH_TOKEN, loginWithGoogle);
          navigate('/');
        }
      }
    });

    const googleLogin = useGoogleLogin({
      onSuccess: tokenResponse => {
        console.log(tokenResponse);
        setGoogleToken({
          ...googleToken,
          token: tokenResponse.access_token,
        });
      },
      onError: error => {
        console.log(error);
        setFormState({
          ...formState,
          error: true,
          error_message: 'Google login failed'
        });
      },
    });
    const remember_me_email = localStorage.getItem(REMEMBER_ME_EMAIL);
    const LOGIN_MUTATION = gql`
      mutation LoginMutation(
        $email: String!
        $password: String!
      ) {
        login(email: $email, password: $password)
      }
    `;
    const navigate = useNavigate();
    const [formState, setFormState] = useState({
      login: true,
      error: false,
      remember: remember_me_email !== null,
      error_message: '',
      email: remember_me_email !== null ? remember_me_email : '',
      password: '',
    });
    const logout = () => {
      localStorage.removeItem(AUTH_TOKEN);
      navigate('/login');
    };
    const checkfields = () => {
      if (formState.email === '') {
        setFormState({
          ...formState,
          error: true,
          error_message: 'Email is required'
        });
        return false;
      }
      if (formState.password === '') {
        setFormState({
          ...formState,
          error: true,
          error_message: 'Password is required'
        });
        return false;
      }
      login();
    }
    const [login] = useMutation(LOGIN_MUTATION, {
      variables: {
        email: formState.email,
        password: Md5.hashStr(formState.password + process.env.SALT)
      },
      onCompleted: ({ login }) => {
        if (formState.remember) {
          localStorage.setItem(REMEMBER_ME_EMAIL, formState.email);
        } else {
          localStorage.removeItem(REMEMBER_ME_EMAIL);
        }
        if (login === 'NOTFOUND') {
          setFormState({
            ...formState,
            error: true,
            error_message: 'User account does not exist'
          });
        } else if (login === 'WRONGPASSWORD') {
          setFormState({
            ...formState,
            error: true,
            error_message: 'Wrong password'
          });
        } else if (login === 'ERROR') {
          setFormState({
            ...formState,
            error: true,
            error_message: 'Error occured'
          });
        } else {
          localStorage.setItem(AUTH_TOKEN, login);
          navigate('/');
        }
      }
    });
    return (
      <Flex
        minH={'100vh'}
        align={'center'}
        justify={'center'}
        bg={useColorModeValue('gray.50', 'gray.800')}>
        <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
          <Stack align={'center'}>
            <Heading fontSize={'4xl'}>Sign in to your account</Heading>
            <Text fontSize={'lg'} color={'gray.600'}>
              to enjoy stories
            </Text>
          </Stack>
          <Box
            rounded={'lg'}
            bg={useColorModeValue('white', 'gray.700')}
            boxShadow={'lg'}
            p={8}>
            <Stack spacing={4}>
              <FormControl id="email" isRequired>
                <FormLabel>Email address</FormLabel>
                <Input type="email"  value={formState.email} onChange={(e) =>
                  setFormState({
                    ...formState,
                    email: e.target.value
                  })
                }/>
              </FormControl>
              <FormControl id="password" isRequired>
                <FormLabel>Password</FormLabel>
                <Input type="password" value={formState.password} onChange={(e) =>
                  setFormState({
                    ...formState,
                    password: e.target.value
                  })
                }/>
              </FormControl>
              <Stack spacing={10}>
                <Stack
                  direction={{ base: 'column', sm: 'row' }}
                  align={'start'}
                  justify={'space-between'}>
                  <Checkbox isChecked={formState.remember} onChange={(e) =>
                    setFormState({
                      ...formState,
                      remember: e.target.checked
                    })
                  }>Remember me</Checkbox>
                  <Link color={'blue.400'} href='/sendPin'>Forgot Password?</Link>
                </Stack>
                <Stack>
                  <Button
                    bg={'blue.400'}
                    color={'white'}
                    _hover={{
                      bg: 'blue.500',
                    }}
                    onClick={() => checkfields()}
                    >
                    Sign in
                  </Button>
                  <Text align="center">or</Text>
                  <Button
                      leftIcon={<FcGoogle />}
                      bg={'blue.400'}
                      color={'white'}
                      _hover={{
                        bg: 'blue.500',
                      }}
                      onClick={() => googleLogin()}
                      >
                      Sign in with Google
                  </Button>
                </Stack>
                { formState.error ? 
                  <Alert status='error'>
                    <AlertIcon />
                    {formState.error_message}
                  </Alert> : ''
                }
                <Text>Don't have an account? <Link color={'blue.400'} href='/signup'>Click here to sign up</Link></Text>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Flex>
    );
  }
